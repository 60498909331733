<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         style="background: rgb(255, 255, 255); display: block; shape-rendering: auto;" width="38px"
         height="38px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(81,50)">
            <g transform="rotate(0)">
                <circle cx="0" cy="0" r="4" fill="#39c0ed" fill-opacity="1">
                    <animateTransform attributeName="transform" type="scale" begin="-0.8571428571428571s"
                                      values="1.76 1.76;1 1"
                                      keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0"
                             begin="-0.8571428571428571s"></animate>
                </circle>
            </g>
        </g>
        <g transform="translate(69.32818385762074,74.23677595650892)">
            <g transform="rotate(51.42857142857143)">
                <circle cx="0" cy="0" r="4" fill="#39c0ed" fill-opacity="0.8571428571428571">
                    <animateTransform attributeName="transform" type="scale" begin="-0.7142857142857143s"
                                      values="1.76 1.76;1 1"
                                      keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0"
                             begin="-0.7142857142857143s"></animate>
                </circle>
            </g>
        </g>
        <g transform="translate(43.10185104735426,80.22276527763654)">
            <g transform="rotate(102.85714285714286)">
                <circle cx="0" cy="0" r="4" fill="#39c0ed" fill-opacity="0.7142857142857143">
                    <animateTransform attributeName="transform" type="scale" begin="-0.5714285714285714s"
                                      values="1.76 1.76;1 1"
                                      keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0"
                             begin="-0.5714285714285714s"></animate>
                </circle>
            </g>
        </g>
        <g transform="translate(22.06996509502501,63.450395912644304)">
            <g transform="rotate(154.2857142857143)">
                <circle cx="0" cy="0" r="4" fill="#39c0ed" fill-opacity="0.5714285714285714">
                    <animateTransform attributeName="transform" type="scale" begin="-0.42857142857142855s"
                                      values="1.76 1.76;1 1"
                                      keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0"
                             begin="-0.42857142857142855s"></animate>
                </circle>
            </g>
        </g>
        <g transform="translate(22.069965095025008,36.5496040873557)">
            <g transform="rotate(205.71428571428572)">
                <circle cx="0" cy="0" r="4" fill="#39c0ed" fill-opacity="0.42857142857142855">
                    <animateTransform attributeName="transform" type="scale" begin="-0.2857142857142857s"
                                      values="1.76 1.76;1 1"
                                      keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0"
                             begin="-0.2857142857142857s"></animate>
                </circle>
            </g>
        </g>
        <g transform="translate(43.101851047354245,19.777234722363467)">
            <g transform="rotate(257.1428571428571)">
                <circle cx="0" cy="0" r="4" fill="#39c0ed" fill-opacity="0.2857142857142857">
                    <animateTransform attributeName="transform" type="scale" begin="-0.14285714285714285s"
                                      values="1.76 1.76;1 1"
                                      keyTimes="0;1" dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0"
                             begin="-0.14285714285714285s"></animate>
                </circle>
            </g>
        </g>
        <g transform="translate(69.32818385762073,25.76322404349107)">
            <g transform="rotate(308.5714285714286)">
                <circle cx="0" cy="0" r="4" fill="#39c0ed" fill-opacity="0.14285714285714285">
                    <animateTransform attributeName="transform" type="scale" begin="0s" values="1.76 1.76;1 1"
                                      keyTimes="0;1"
                                      dur="1s" repeatCount="indefinite"></animateTransform>
                    <animate attributeName="fill-opacity" keyTimes="0;1" dur="1s" repeatCount="indefinite" values="1;0"
                             begin="0s"></animate>
                </circle>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "LoaderIcon"
}
</script>

<style>

</style>
