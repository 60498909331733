<template>
  <div class="baseInput">
    <input
      v-if="autocomplete"
      class="baseInput__area"
      :class="{ baseInput__error: error }"
      :type="type"
      :maxlength="maxlength"
      :minlength="minlength"
      :readonly="readOnly"
      @focusin="focusIn()"
      @focusout="focusOut()"
      @input="onInputChange"
      :value="modelValue"
    />
    <input
      v-else
      readonly
      v-maska="mask"
      onfocus="this.removeAttribute('readonly')"
      class="baseInput__area"
      :class="{ baseInput__error: error }"
      :type="type"
      :maxlength="maxlength"
      :minlength="minlength"
      @focusin="focusIn()"
      @focusout="focusOut()"
      @input="onInputChange"
      :value="modelValue"
    />
    <label
      class="baseInput__label"
      :class="{ 'baseInput__area-focus': focused }"
    >
      <slot></slot>
    </label>
    <small v-if="error">{{ printError(errorMessage) }}</small>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    mask: String,
    autocomplete: false,
    errorMessage: Object,
    readOnly: false,
    maxlength: Number,
    minlength: Number,
    type: "",
    error: false,
    modelValue: [Number, String],
  },
  data() {
    return {
      focused: false,
    };
  },
  mounted() {
    this.focusMounted();
  },
  methods: {
    focusMounted() {
      if (this.modelValue !== undefined && this.modelValue !== null) {
        if (this.modelValue.length !== 0) {
          this.focused = true;
        }
      }
    },
    focusIn() {
      this.focused = true;
    },
    focusOut() {
      if (this.modelValue === null || this.modelValue === undefined) {
        this.focused = false;
        return;
      }
      if (this.modelValue.length === 0) {
        this.focused = false;
      }
    },
    onInputChange(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    printError(errors) {
      let name;
      let param;
      if (errors && errors[0]) {
        name = errors[0].$validator;
        param = errors[0].$params;
        if (name === "required") {
          return this.$t("fieldRequireError");
        } else if (name === "minLength") {
          return (
            this.$t("ThisMinimumLength") + param.min + this.$t("characters")
          );
        } else if (name === "email") {
          return this.$t("FieldMustBeEmail");
        } else if (name === "sameAs") {
          return this.$t("FieldMustBeEquality");
        }
      } else {
        return "";
      }
    },
  },
  watch: {
    modelValue() {
      this.focusMounted();
    },
  },
};
</script>

<style></style>
