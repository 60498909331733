<template>
  <component
    class="d-flex align-items-center"
    :is="link ? 'router-link' : 'div'"
    :to="link"
  >
    <loader-icon v-if="isLoaded" class="me-2" />
    <button
      type="button"
      class="buttons"
      :class="{
        buttons__header: header,
        buttons__calendar: calendar,
        buttons__sidebar: sidebar,
        'buttons__sidebar-active': sideBarActivePageValue(link),
        buttons__sort: sort,
        'buttons__sort-active': active,
        buttons__bookExpert: book,
        buttons__back: back,
        buttons__load: load,
      }"
    >
      <span v-if="haveIcon" class="header__icon"><slot name="icon" /></span>
      <slot />
    </button>
  </component>
</template>

<script>
import LoaderIcon from "@/components/UI/icons/preloaders/LoaderIcon";

export default {
  components: { LoaderIcon },
  props: {
    isLoaded: false,
    calendar: Boolean,
    header: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Boolean,
      default: false,
    },
    book: {
      type: Boolean,
      default: false,
    },
    link: null,
    sidebar: {
      type: Boolean,
      default: false,
    },
    sort: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  name: "BaseButton",
  methods: {
    sideBarActivePageValue(value) {
      return this.$route.path === value;
    },
  },
  computed: {
    haveIcon() {
      return !!this.$slots.icon;
    },
  },
};
</script>

<style></style>
